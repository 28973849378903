import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';

const US_ADDRESS_SUGGESTION_SMARTY_API_URL = 'us-autocomplete-pro.api.smartystreets.com';
const INTERNATIONAL_ADDRESS_SUGGESTION_SMARTY_API_URL = 'international-autocomplete.api.smarty.com/v2';

const Autocomplete = ({
  onChange,
  value,
  onInputChange,
  classes,
  onFocus,
  clearField = false,
  selectedCountry = ['us', 'ca'],
}: any) => {
  const inputRef = useRef(null);
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);

  useEffect(() => {
    setInput(value);
  }, []);

  useEffect(() => {
    if(clearField)
      setInput('');
  }, [clearField]);

  const isCountryUS = () => {
    return selectedCountry === 'us'
  }

  const sendSuggestionRequest = useCallback(
    debounce(async (typedInput) => {
      const url = isCountryUS() ? US_ADDRESS_SUGGESTION_SMARTY_API_URL : INTERNATIONAL_ADDRESS_SUGGESTION_SMARTY_API_URL;
      if(typedInput) {
        try {
          const response = axios.get(`https://${url}/lookup`, {
            params: {
              key: process.env.REACT_APP_SMARTY_KEY,
              country: selectedCountry,
              search: typedInput || ' '
            }
          });

          response.then((response) => {
            setShowSuggestion(true)
            const data = isCountryUS() ? response.data.suggestions : response.data.candidates;
            setSuggestions(data || []);
          })
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      }
    }, 300), []
  );

  const handleInputChange = (evt: any) => {
    setInput(evt.target.value);
    onInputChange(evt.target.value);
    sendSuggestionRequest(evt.target.value)
  };

  const onSuggestionClick = (suggestion: any) => {
    if(isCountryUS()) {
      onChange(suggestion)
    } else {
      const dataArr = suggestion.address_text.split(' ')
      const data = {
        street_line: dataArr.slice(0, dataArr.length - 4).join(' '),
        city: dataArr[dataArr.length - 4],
        state: dataArr[dataArr.length - 3],
        zipcode: dataArr.slice(-2).join(' '),
        address_id: suggestion.address_id
      }
      onChange(data)
    }
    setShowSuggestion(false)
  }

  const onFocusHandler = (evt: any) => {
    onFocus && onFocus(evt)
  }

  const onBlurHandler = (evt: any) => {
    setTimeout(() =>{
      setShowSuggestion(false)
    }, 500)
  }

  return <>
    <input
      ref={inputRef}
      onChange={handleInputChange}
      value={input}
      type='text'
      className={`block w-full rounded-lg px-4 py-3 text-sm ${classes}`}
      placeholder=''
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
    />
    {showSuggestion && suggestions.length > 0 && (
      <ul className="border border-gray-300 rounded-md shadow-lg bg-white max-h-60 overflow-auto">
        {suggestions.map((suggestion: any, index) => (
          <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onClick={() => onSuggestionClick(suggestion)}>
            {isCountryUS() ? `${suggestion.street_line} ${suggestion.secondary} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}` : suggestion.address_text}
          </li>
        ))}
      </ul>
    )}
  </>
};

export default Autocomplete;
