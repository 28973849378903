import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavLinks from './nav-links';
import { useAuth } from '../useAuth';
import { Button } from '../Button';
import GiftIcon from '../Icons/GiftIcon';
import RequestQuota from './RequestQuota';

const Navbar = () => {
  const token = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('userData') || '{}'));
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);
  const handleStorage = () => {
    setUserData(JSON.parse(localStorage.getItem('userData') || '{}'));
  };

  const requestQuotaButtonVisible = () => (
    !userData?.quota?.['US']?.balance ||
    !userData?.quota?.['CA']?.balance ||
    userData?.quota?.['CA']?.balance === 0 ||
    userData?.quota?.['CA']?.balance === 0
  );

  return (
    <header className='fixed top-0 z-10 flex h-16 w-full items-center justify-between overflow-hidden bg-white px-4'>
      <div className='flex'>
        {(userData?.role === 'ADMIN' || userData?.role === 'MANAGER') ? (
          <Link to="/admin/order">
            <img src={'../../assets/ns-logo.png'} className='w-32'></img>
          </Link>
        ) : (
          <Link to="/user/order">
            <img src={'../../assets/ns-logo.png'} className='w-32'></img>
          </Link>
        )}
        <NavLinks isAuth={token} user={userData} />
      </div>
      {token && (
        <div className='flex justify-between gap-4'>
          <ul className={`grid grid-cols-${requestQuotaButtonVisible() ? 4 : 3} justify-center`}>
            <li className='px-1'>
              <Button size='small' onClick={() => { navigate('/send-gift'); }}>
                <GiftIcon></GiftIcon>Send new gift
              </Button>
            </li>
            {requestQuotaButtonVisible() && (
              <li className='px-1'>
                <RequestQuota/>
              </li>
            )}
            <li className='px-1'>
              <div className='flex items-center justify-center gap-2 pt-2.5'>
                <img src='/assets/us-ico.svg' />
                <span title='Your monthly remaining gift allocation' className='cursor-pointer'>
                  {userData?.quota?.['US']?.balance ?? 0}{' '}
                </span>
                <img src='/assets/sep-ico.svg' />
                <img src='/assets/ca-ico.svg' />
                <span title='Your monthly remaining gift allocation' className='cursor-pointer'>
                  {userData?.quota?.['CA']?.balance ?? 0}
                </span>
              </div>
            </li>
            <li>
              <div className='flex cursor-pointer items-center gap-4 pt-2.5'>
                <img src='/assets/avatar.svg' title={userData?.email}/>
                <Link to="/logout" className="flex items-center gap-2">
                  <img src='/assets/log-out.svg' />
                  <span>Logout</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Navbar;
